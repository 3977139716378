// Sample React components for each section with CSS styling

import React from 'react';
import './Managing.css';
import deve from '../assets/deve.jpg';
import finance from '../assets/finance.avif';
import operations from '../assets/operations.jpg';
import Hr from '../assets/Hr.jpg';
import bpo from '../assets/bpo.jpg';

const Managing = () => {
  return (
    <div className="trainingContainer">
        <h2>Managment Services</h2>
        <p>
        Business Management as a Service refers to the comprehensive management 
        of various business operations and functions, provided as an outsourced service to organizations. It includes a range of activities that ensure the smooth running of day-to-day business operations, such as strategic planning, financial management, 
        human resources, marketing, project management, and compliance.
        </p>
      <section className="introSection">
        <img src={deve} alt="Develoiping Employee" />
        <h2>Training Developing and Managing</h2>
        <p> It equips employees with the skills and knowledge needed to perform their 
            roles effectively, enhancing productivity and job satisfaction. Through structured training programs, employees gain confidence in their abilities and stay updated with industry trends, technologies, and best practices. Development initiatives, such as mentorship and leadership training, prepare employees for future roles within the organization, fostering a culture of continuous learning and career growth. Investing in employee development not only boosts performance but also strengthens loyalty, as employees feel valued and motivated to contribute 
            to the organization’s long-term objectives.</p>
            <p>
                1. Skill Development
            </p>
            <p> 2.Feedback and Evaluation</p>
            <p>
                3.Strategic Planning.
            </p>
               
            
            <p>4.Sales Revenue Analysing</p>
            <p>
                5.Learnng Managment System(LMS).
            </p>
           
      </section>

      <section className="skillsAssessment">
        <img src={finance} alt="Finance" />
        <h3>Financial Services</h3>
        <p>Our financial services offer comprehensive solutions designed to 
            support both individuals and businesses in managing, growing, and securing their financial assets. We provide a range of services, including investment advisory, wealth management, tax planning, and retirement strategies, all tailored to meet unique financial goals. Through expert analysis and personalized financial planning, our team helps clients navigate complex financial landscapes, optimize their portfolios, and achieve long-term financial security. Whether you're looking to invest, manage risk, or plan for future financial needs, our services are here to provide
             the insights and support necessary to make informed, confident decisions.</p>
             <p>
                1. Banking Services
            </p>
            <p> 2.Wealth Managment</p>
               
            
            <p>3.Tax and Auditing</p>
            <p>
                4.Insurance
            </p>
      </section>

      <section className="programDesign">
        <img src={operations} alt="Operations" />
        <h3>Operations Managment</h3>
        <p>
        Operations management is the practice of overseeing and optimizing an
         organization's processes to produce goods or deliver services efficiently and effectively. It involves planning, organizing, and supervising every step of production or service delivery to ensure that resources are used wisely, costs are minimized, and output meets quality standards. Operations managers coordinate various aspects, such as inventory control, logistics, quality assurance, and workforce management, all while adapting to changing demands and market conditions. Through continuous process improvement, data analysis, and strategic resource allocation, operations management helps businesses achieve smooth workflows, high customer satisfaction, and sustainable profitability. This discipline is essential for aligning an organization’s operational activities with its overall goals, 
        driving both competitive advantage and long-term success.</p>
      </section>

      

      <section className="feedbackEvaluation">
        <img src={Hr} alt="Hr" />
        <h3>HR and Payroll</h3>
        <p>HR and payroll services are essential for managing an
             organization's workforce and ensuring that employees are compensated accurately 
             and on time. These services streamline human resources functions, reduce administrative burden, and help businesses stay compliant with labor laws and
             tax regulations. Here’s a breakdown of HR and payroll services:</p>
             <p>
                1. Recruiting and Staffing.
            </p>
            <p> 2.Training and Development</p>
               
            
            <p>3.Compliance and Legal Services</p>
            <p>
                4.Performance management
            </p>
      </section>

      <section className="impactGrowth">
        <img src={bpo} alt="BPO" />
        <h3>BPO (Business Process Outsourcing) </h3>
        <p>Services refer to the practice of outsourcing specific
             business operations or processes to a third-party service provider. 
             These services allow organizations to focus on their core competencies while external providers handle non-core functions. BPO can cover a broad range of business operations, from customer service and human resources to finance and 
            accounting. Here’s a breakdown of common BPO services:</p>
      </section>
    </div>
  );
};

export default Managing;
