import React from 'react';
import { Link } from 'react-router-dom';
import './DigitalMarketing.css';
import find from '../assets/find.jpg';
import { Button } from 'react-bootstrap';


const DigitalMarketing = () => {
  const services = [
    { id: 1, title: 'SEO', description: 'Boost your website’s visibility on search engines like Google, Bing, and Yahoo. Our SEO services focus on improving organic rankings through on-page and off-page optimization.', link: '/seo' },
    { id: 2, title: 'Content Marketing', description: 'Drive engagement with valuable, informative content that resonates with your target audience. Content marketing builds trust and positions your brand as an authority.', link: '/Content Marketing' },
    { id: 3, title: 'Social Media Marketing', description: 'Connect with customers where they spend most of their time—on social media. Our SMM strategies help you grow an online following, engage your audience, and increase brand loyalty.', link: '/Social Media Marketing' },
    { id: 4, title: 'Pay-Per-Click Advertising', description: 'Get immediate visibility and drive targeted traffic with ads on Google, Bing, and social media platforms. PPC offers fast results and measurable ROI. and increase Market cap', link: '/PPC' },
    { id: 5, title: 'Email Marketing', description: 'Engage your audience and convert leads through targeted email campaigns. Email marketing is a cost-effective way to maintain direct communication with potential customers.', link: '/Email Marketing' },
    { id: 6, title: 'Web Analytics and Reporting', description: 'Track, analyze, and interpret user behavior to make informed decisions. Web analytics helps refine your strategies.', link: '/Web Analytic Reporting' },
    
    // Add more services as needed
  ];

  return (
    <div className="digital-marketing-page">



<section className="introSection">
<h2>Digital Marketing Services</h2>
        <p> Digital Marketing Services encompass a broad range of online marketing strategies and 
          tactics that businesses use to promote their products, services, or brands across digital channels. These services help businesses reach their target audience, increase visibility, drive traffic, and generate leads. Here’s a
           breakdown of all the major digital marketing services:</p>
            <p>
                1. Search Engine Optimization (SEO).
            </p>
            <p> 2.Content Marketing.</p>
               
            
            <p>3.Social Media Marketing.</p>
            <p>
                4.Pay-Per-Click Advertising (PPC)..
            </p>
            <p>
                5.Email Marketing.
            </p>
            <p>
                6.Web Analytics and Reporting.
            </p>
            <a href="Contact" className="hero-button">Get Ur Quote</a>

        <img src={find} alt="Digital Marketing" />
       
      </section>





      <div className="services-grid">
        {services.map(service => (
          <div className="service-card" key={service.id}>
            <h2>{service.title}</h2>
            <p>{service.description}</p>
            <Link to={service.link} className="view-details-btn">View Details</Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DigitalMarketing;
