// EnquiryForm.js
import React, { useState } from 'react';
import { db, collection, addDoc } from './firebase'; // Firebase setup
import './Contact.css'; // Import CSS
import contu from '../assets/contu.jpg';
import { FaWhatsapp, FaEnvelope } from 'react-icons/fa'; // Import icons
import { Button } from 'react-bootstrap';


const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add form data to Firebase Firestore
      await addDoc(collection(db, 'contacts'), formData);
      alert('Your enquiry has been sent successfully!');
      setFormData({ name: '', email: '', phone: '', message: '' }); // Reset form
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('There was an issue sending your enquiry. Please try again.');
    }
  };

  return (
    <div className="enquiry-container">
      <div className="enquiry-image">
        <img src={contu} alt="Enquiry" />
      </div>
      <div className="enquiry-form">
        <h2>
          <strong>We Raynn Business Consultancy!</strong></h2>
        <p>
           
        </p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Full Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
         
          <Button type="submit">Submit</Button>
        </form>

        <div className="contact-info">
            {/* WhatsApp Contact */}
          
            <a href="https://wa.me/ +91 9052216640" className="contact-link">
              <FaWhatsapp className="icon" />
              +91 9052216640
            </a>
            
           <br></br>
            {/* Email Contact */}
            <a href="https://mail.google.com/mail/u/0/#inbox" className="contact-link">
              <FaEnvelope className="icon" />
              info@raynnbusinessconsultancy.com
            </a>
           
          </div>
      </div>
    </div>
  );
};

export default Contact;
