import React from "react";
import "./Careers.css";

const jobs = [
  {
    id: 1,
    title: "Data Analyst",
    location: "Hyderabad, India",
    type: "Full-Time",
    description:
      "We are looking for a skilled Data Analyst to join our team. Must have experience in python and power Bi",
  },
  {
    id: 2,
    title: "Digital Marketing Specialist",
    location: "Remote",
    type: "Part-Time",
    description:
      "Join our marketing team to strategize and implement digital campaigns. SEO and PPC experience required.",
  },
  {
    id: 3,
    title: "Project Manager",
    location: "Hyderabad, India",
    type: "Full Time",
    description:
      "Manage software development projects and coordinate with cross-functional teams.",
  },
];

const Careers = () => {
  return (
    <div className="careers-page">
      <h1 className="careers-title">Join Our Team</h1>
      <p className="careers-intro">
        We are always on the lookout for talented individuals.
      </p>
      <div className="jobs-container">
        {jobs.map((job) => (
          <div key={job.id} className="job-card">
            <h2 className="job-title">{job.title}</h2>
            <p className="job-location">
              <strong>Location:</strong> {job.location}
            </p>
            <p className="job-type">
              <strong>Type:</strong> {job.type}
            </p>
            <p className="job-description">{job.description}</p>
            <a href="Contact" className="hero-button">Apply Now</a>

            
          </div>
        ))}
      </div>
    </div>
  );
};

export default Careers;
