import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Header.css";
import rrr from '../assets/rrr.png';

const Header = () => {
  return (
    <Navbar bg="light" variant="light" expand="lg" className="custom-navbar">
      <Container>
        {/* Logo linking to Home */}
        <Navbar.Brand as={Link} to="/" className="logo">
          <img
            src={rrr}
            alt="Logo"
           
            className="logo-image"
          />
         
        </Navbar.Brand>

        {/* Toggler for mobile view */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {/* Right-side navigation icons */}
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/" className="nav-icon">
              <i className="fas fa-home"></i> Home
            </Nav.Link>
            <Nav.Link as={Link} to="/about" className="nav-icon">
              <i className="fas fa-info-circle"></i> About
            </Nav.Link>
            <Nav.Link as={Link} to="/services" className="nav-icon">
              <i className="fas fa-briefcase"></i> IT Services
            </Nav.Link>
            <Nav.Link as={Link} to="/Careers" className="nav-icon">
              <i className="fas fa-user-tie"></i> Careers
            </Nav.Link>

            <Nav.Link as={Link} to="/Digital Marketing" className="nav-icon">
              <i className="fas fa-chart-line"></i> Digital Marketing
            </Nav.Link>

            <Nav.Link as={Link} to="/Management" className="nav-icon">
              <i className="fas fa-briefcase"></i> Management
            </Nav.Link>

            

            <Nav.Link as={Link} to="/contact" className="nav-icon">
              <i className="fas fa-envelope"></i> Contact
            </Nav.Link>


          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
