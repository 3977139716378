// Footer.js
import React from 'react';
import './Footer.css'; // Import CSS for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h4>Follow Link</h4>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="about">About</a></li>
            <li><a href="Contact">Contact</a></li>
            <li><a href="services">IT Services</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Contact Us</h4>
          <p>Email: info@raynnbusinessconsultancy.com</p>
          <p><strong>UK office:</strong> 23 Ainslie Road Preston UK.</p>
          <p>Phone:+44 7747290284</p>
          
          <p><strong>Branch office:</strong> 1454 Sri Lane, Madhapur, Hyderabad.</p>
          <p>Phone: +91 9052216640 </p>
          

          <a href="Contact" className="hero-button">Enquire Now</a>

          <div className="footer-bottom">
                <p>&copy; {(2020)} Raynn Business Consultancy. All rights reserved.</p>
            </div>
        </div>
        
        <div className="social-icons">
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                        <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                        <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                
        </div>
      </div>
     
    </footer>
  );
};

export default Footer;
