import React from 'react';
import './About.css';
import abouts from '../assets/abouts.jpg';
import reac from '../assets/reac.png';
import Monday from '../assets/Monday.png';
import python from '../assets/Python.png';
import Dynamics from '../assets/Dynamics.jpg';
import power from '../assets/power.png';
import tableau from '../assets/tableau.webp';

function About() {
    return (
<div className="section">
        <h2>About Us</h2>
        <p>
          Welcome to Raynn Buisness Consultancy! We are a team of passionate professionals dedicated to providing top-notch 
          solutions to our clients. 
        
        
          With expertise across various fields, we are committed to innovation, excellence, and customer satisfaction. 
          We believe in building lasting relationships with our clients and strive to be a trusted partner in their success.
        
        </p>

        <div className="about-us">
            {/* Banner Section with Main Image */}
            <div className="about-us-banner">
                
               
            </div>

            {/* Vision Section */}
            <div className="section">
                <h2>Our Vision</h2>
                <p>
                    Our vision is to be the leading IT consulting firm, empowering businesses with innovative technology solutions and helping them achieve sustained growth in a dynamic digital landscape.
                </p>
            </div>

            {/* Mission Section */}
            <div className="section">
                <h2>Our Mission</h2>
                <p>
                    Our mission is to provide exceptional IT consulting services that enable our clients to harness the power of technology for success. We strive to deliver customized solutions that meet each client's unique needs, ensuring quality, efficiency, and satisfaction.
                </p>
            </div>

            {/* Values Section */}
            <div className="section">
                <h2>Our Values</h2>
                <ul>
                    <li><strong>Integrity:</strong> We uphold the highest standards of integrity in all our actions.</li>
                    <li><strong>Innovation:</strong> We constantly seek innovative solutions to drive our clients' success.</li>
                    <li><strong>Customer Focus:</strong> Our clients' needs are at the heart of everything we do.</li>
                    <li><strong>Excellence:</strong> We strive for excellence in every project we undertake.</li>
                </ul>
            </div>

            {/* Image Gallery Section */}
            <div className="section gallery">
                <h2>Our Top IT Partners</h2>
                <div className="gallery-images">
                    <img src={reac} alt="Our Team at Work" />
                    <img src={python} alt="Company Event" />
                    <img src={Monday} alt="Client Collaboration" />
                    <img src={tableau} alt="Technology Showcase" />
                    <img src={Dynamics} alt="Technology Showcase" />
                    <img src={power} alt="Technology Showcase" />
                </div>
            </div>
        </div>
        </div>
    );
}

export default About;
