// src/components/HomePage.js
import React from 'react';
import './Home.css'; // Import CSS for styling

import HeroSection from '../components/Herosection';
import first from '../assets/first.jpg';
import dgm from '../assets/dgm.jpg';
import marketing  from '../assets/marketing.jpg';



const Home = () => {
  return (
    <div className="home-page">
      <HeroSection/>

      <section className="content-image-section">
        <div className="content">
          <h2>Welcome to <strong>Raynn</strong> Business Consultancy</h2>
          <p>
            We offer a wide range of IT services to help you grow your business. From web development to IT support.
            
            Every business requires a unique, practical approach when it comes to facing growing business
             requirements regarding their IT infrastructure. In a nonstop business environment, staying prepared
              is of the utmost importance for any company that wishes to stay ahead of their competition. Whether you 
              require extensive data protection, information about potential networks, or someone to guide you through 
              the ins and outs
             of cloud computing services and necessary upgrades, our team is ready to help.
          </p>
          <a href="services" className="hero-button">Know More</a>
        </div>
        <div className="image">
          <img src={first} alt="IT Consultancy" />
        </div>
      </section>

      <section className="image-content-section">
        <div className="image">
          <img src={marketing} alt="Services" />
        </div>
        <div className="content">
          <h2> Digital Marketing </h2>
          <p>
          We deliver all the digital marketing services you need.
          53.3% of all web traffic comes from organic search and 60% of marketers state that their highest
           quality leads come from SEO-engaged customers
       
          Get more web traffic from organic search, win more customers from PPC ads,
          Our data-driven PPC campaigns make sure you’re always getting the best ROI and generating the most possible revenue 
          from your ad spend
           improve lead generation from content marketing, boost brand awareness with Digital 
           PR or get a website that converts visitors.
           Increase brand awareness and get in front of your customers on major social networks like Facebook, 
           Instagram and TikTok with targeted social media campaigns.
           Emails allow businesses to stay in touch with customers even after a sale, creating opportunities for repeat business. Through follow-ups, loyalty programs, and exclusive offers, 
           email marketing fosters customer loyalty and long-term relationships.

          
          </p>
          <a href="Digital Marketing" className="hero-button">Know More</a>
        </div>
      </section>

      <section className="content-image-section">
        <div className="content">
          <h2>Develop your Business</h2>
          <p>
          Business development can be challenging.

With the convergence of new technologies and market disruption, I understand the obstacles you may be facing.

And yet with disruption comes new opportunity.

That’s why now may be exactly the right time to re-evaluate your business development strategy.

we can help you make the best strategic choices for profitable long-term growth.

Moreover, we can work with your team on implementation to ensure you avoid common pitfalls and get better and faster results.
we provide help in training and educating the employees of the company and moniter 
That’s why the first step of a business development project is often a strategy workshop where we explore the best ideas from across and outside the organisation.


          </p>
          <a href="Managment" className="hero-button">Know More</a>
        </div>
        <div className="image">
          <img src={dgm} alt="Contact Us" />


            

        </div>

      </section>
      


      
    </div>
  );
};

export default Home;
